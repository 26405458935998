var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"demo-spin-article"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-3"},[_vm._v(" "+_vm._s(_vm.$t("customer.province"))+" ")]),_c('div',{staticClass:"col-md-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.province_code),expression:"model.province_code"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has(
                                            'province_code'
                                        )
                                    },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "province_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.provinceChange(_vm.model.province_code)}],"input":function($event){_vm.model.district_code = _vm.model.commune_code = _vm.model.village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.provinces),function(province,index){return _c('option',{key:index,domProps:{"value":province.province_code}},[_vm._v(" "+_vm._s(province.name_en)+" | "+_vm._s(province.name_kh)+" ")])})],2),(_vm.errors.has('province_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("province_code"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-3"},[_vm._v(" "+_vm._s(_vm.$t("customer.district"))+" ")]),_c('div',{staticClass:"col-md-9"},[_c('ts-loading-banner',{attrs:{"loading":_vm.province_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.district_code),expression:"model.district_code"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'district_code'
                                            )
                                        },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "district_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.districtChange(
                                                _vm.model.district_code
                                            )}],"input":function($event){_vm.model.commune_code = _vm.model.village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.districts),function(district,index){return _c('option',{key:index,domProps:{"value":district.district_code}},[_vm._v(" "+_vm._s(district.name_en)+" | "+_vm._s(district.name_kh)+" ")])})],2)]),(_vm.errors.has('district_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("district_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-3"},[_vm._v(" "+_vm._s(_vm.$t("customer.commune"))+" ")]),_c('div',{staticClass:"col-md-9"},[_c('ts-loading-banner',{attrs:{"loading":_vm.district_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.commune_code),expression:"model.commune_code"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'commune_code'
                                            )
                                        },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "commune_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.communeChange(
                                                _vm.model.commune_code
                                            )}],"input":function($event){_vm.model.village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.communes),function(commune,index){return _c('option',{key:index,domProps:{"value":commune.commune_code}},[_vm._v(" "+_vm._s(commune.name_en)+" | "+_vm._s(commune.name_kh)+" ")])})],2)]),(_vm.errors.has('commune_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("commune_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-3"},[_vm._v(" "+_vm._s(_vm.$t("customer.village"))+" ")]),_c('div',{staticClass:"col-md-9"},[_c('ts-loading-banner',{attrs:{"loading":_vm.commune_change}},[_c('div',{staticClass:"input-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.village_code),expression:"model.village_code"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.errors.has(
                                                    'village_code'
                                                )
                                            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "village_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.villages),function(village,index){return _c('option',{key:index,domProps:{"value":village.village_code}},[_vm._v(" "+_vm._s(village.name_en)+" | "+_vm._s(village.name_kh)+" ")])})],2),(
                                                !_vm.villageSaving &&
                                                    _vm.model.commune_code
                                            )?_c('Poptip',{attrs:{"title":_vm.$t('customer.village'),"transfer":true,"width":"240"},on:{"on-popper-show":function () { return (_vm.name_en = _vm.name_kh = ''); }}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('add')),expression:"$t('add')"}],staticClass:"btn btn-info",attrs:{"disabled":_vm.villageSaving}},[(_vm.villageSaving)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('i',{staticClass:"fas fa-plus"})]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t( "customer.nameEn" )))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                                                            _vm.name_en
                                                        ),expression:"\n                                                            name_en\n                                                        ",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                                            'is-invalid':
                                                                _vm.errors.has(
                                                                    'name_en'
                                                                ) ||
                                                                _vm.$v.name_en
                                                                    .$error
                                                        },attrs:{"type":"text"},domProps:{"value":(
                                                            _vm.name_en
                                                        )},on:{"input":function($event){if($event.target.composing){ return; }
                                                            _vm.name_en
                                                        =$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                                            _vm.nameEnErrors.length >
                                                                0
                                                        )?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.nameEnErrors[0]))]):_vm._e(),(
                                                            _vm.errors.has(
                                                                'name_en'
                                                            )
                                                        )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first( "name_en" ))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t( "customer.nameKh" )))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                                                            _vm.name_kh
                                                        ),expression:"\n                                                            name_kh\n                                                        ",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                                            'is-invalid':
                                                                _vm.errors.has(
                                                                    'name_kh'
                                                                ) ||
                                                                _vm.$v.name_kh
                                                                    .$error
                                                        },attrs:{"type":"text"},domProps:{"value":(
                                                            _vm.name_kh
                                                        )},on:{"input":function($event){if($event.target.composing){ return; }
                                                            _vm.name_kh
                                                        =$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                                            _vm.nameEnErrors.length >
                                                                0
                                                        )?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.nameEnErrors[0]))]):_vm._e(),(
                                                            _vm.errors.has(
                                                                'name_kh'
                                                            )
                                                        )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first( "name_kh" ))+" ")]):_vm._e()]),_c('div',{staticClass:"tw-mt-2 tw-text-right"},[_c('a',{staticClass:"ivu-btn ivu-btn-primary ivu-btn-small",on:{"click":function($event){$event.preventDefault();return _vm.onSaveVillage.apply(null, arguments)}}},[_c('span',[_vm._v("Save")])])])])]):_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('add')),expression:"$t('add')"}],staticClass:"btn btn-default",attrs:{"disabled":!_vm.model.commune_code}},[(_vm.villageSaving)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('i',{staticClass:"fas fa-plus"})])],1)]),(_vm.errors.has('village_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("village_code"))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-3"},[_vm._v(" "+_vm._s(_vm.$t("customer.street"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.street_no),expression:"model.street_no"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has(
                                            'street_no'
                                        )
                                    },attrs:{"type":"text"},domProps:{"value":(_vm.model.street_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "street_no", $event.target.value)}}}),(_vm.errors.has('street_no'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("street_no"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-3"},[_vm._v(" "+_vm._s(_vm.$t("customer.house"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.house_no),expression:"model.house_no"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has('house_no')
                                    },attrs:{"type":"text"},domProps:{"value":(_vm.model.house_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "house_no", $event.target.value)}}}),(_vm.errors.has('house_no'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("house_no"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-3"},[_vm._v(" "+_vm._s(_vm.$t("customer.description"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.description),expression:"model.description"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has(
                                            'description'
                                        )
                                    },attrs:{"type":"text","rows":"3"},domProps:{"value":(_vm.model.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "description", $event.target.value)}}}),(_vm.errors.has('description'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("description"))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"col-md-3"})])]),(_vm.loadingPreData)?_c('Spin',{attrs:{"size":"large","fix":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }