<template>
    <div>
        <div class="demo-spin-article">
            <div class="row">
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-3"
                                >
                                    {{ $t("customer.province") }}
                                </label>
                                <div class="col-md-9">
                                    <select
                                        v-model="model.province_code"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'province_code'
                                            )
                                        }"
                                        @change="
                                            provinceChange(model.province_code)
                                        "
                                        @input="
                                            model.district_code = model.commune_code = model.village_code = null
                                        "
                                    >
                                        <option
                                            :value="null"
                                            disabled
                                            selected
                                            style="display: none;"
                                            >{{ $t("select") }}</option
                                        >
                                        <option
                                            v-for="(province,
                                            index) in provinces"
                                            :key="index"
                                            :value="province.province_code"
                                        >
                                            {{ province.name_en }} |
                                            {{ province.name_kh }}
                                        </option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('province_code')"
                                    >
                                        {{ errors.first("province_code") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-3"
                                >
                                    {{ $t("customer.district") }}
                                </label>
                                <div class="col-md-9">
                                    <ts-loading-banner
                                        :loading="province_change"
                                    >
                                        <select
                                            v-model="model.district_code"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'district_code'
                                                )
                                            }"
                                            @change="
                                                districtChange(
                                                    model.district_code
                                                )
                                            "
                                            @input="
                                                model.commune_code = model.village_code = null
                                            "
                                        >
                                            <option
                                                :value="null"
                                                disabled
                                                selected
                                                style="display: none;"
                                                >{{ $t("select") }}</option
                                            >
                                            <option
                                                v-for="(district,
                                                index) in districts"
                                                :key="index"
                                                :value="district.district_code"
                                            >
                                                {{ district.name_en }} |
                                                {{ district.name_kh }}
                                            </option>
                                        </select>
                                    </ts-loading-banner>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('district_code')"
                                    >
                                        {{ errors.first("district_code") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-3"
                                >
                                    {{ $t("customer.commune") }}
                                </label>
                                <div class="col-md-9">
                                    <ts-loading-banner
                                        :loading="district_change"
                                    >
                                        <select
                                            v-model="model.commune_code"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'commune_code'
                                                )
                                            }"
                                            @change="
                                                communeChange(
                                                    model.commune_code
                                                )
                                            "
                                            @input="model.village_code = null"
                                        >
                                            <option
                                                :value="null"
                                                disabled
                                                selected
                                                style="display: none;"
                                                >{{ $t("select") }}</option
                                            >
                                            <option
                                                v-for="(commune,
                                                index) in communes"
                                                :key="index"
                                                :value="commune.commune_code"
                                            >
                                                {{ commune.name_en }} |
                                                {{ commune.name_kh }}
                                            </option>
                                        </select>
                                    </ts-loading-banner>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('commune_code')"
                                    >
                                        {{ errors.first("commune_code") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-3"
                                >
                                    {{ $t("customer.village") }}
                                </label>
                                <div class="col-md-9">
                                    <ts-loading-banner
                                        :loading="commune_change"
                                    >
                                        <div class="input-group">
                                            <select
                                                v-model="model.village_code"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'village_code'
                                                    )
                                                }"
                                            >
                                                <option
                                                    :value="null"
                                                    disabled
                                                    selected
                                                    style="display: none;"
                                                    >{{ $t("select") }}</option
                                                >
                                                <option
                                                    v-for="(village,
                                                    index) in villages"
                                                    :key="index"
                                                    :value="
                                                        village.village_code
                                                    "
                                                >
                                                    {{ village.name_en }} |
                                                    {{ village.name_kh }}
                                                </option>
                                            </select>
                                            <Poptip
                                                :title="$t('customer.village')"
                                                :transfer="true"
                                                width="240"
                                                @on-popper-show="
                                                    () =>
                                                        (name_en = name_kh = '')
                                                "
                                                v-if="
                                                    !villageSaving &&
                                                        model.commune_code
                                                "
                                            >
                                                <a
                                                    class="btn btn-info"
                                                    v-tooltip="$t('add')"
                                                    :disabled="villageSaving"
                                                >
                                                    <i
                                                        class="fas fa-circle-notch fa-spin"
                                                        v-if="villageSaving"
                                                    ></i>
                                                    <i
                                                        class="fas fa-plus"
                                                        v-else
                                                    ></i>
                                                </a>
                                                <div slot="content">
                                                    <div class="mb-2">
                                                        <label
                                                            class="required"
                                                            >{{
                                                                $t(
                                                                    "customer.nameEn"
                                                                )
                                                            }}</label
                                                        >
                                                        <input
                                                            v-model.trim="
                                                                name_en
                                                            "
                                                            type="text"
                                                            class="form-control"
                                                            :class="{
                                                                'is-invalid':
                                                                    errors.has(
                                                                        'name_en'
                                                                    ) ||
                                                                    $v.name_en
                                                                        .$error
                                                            }"
                                                        />
                                                        <span
                                                            class="invalid-feedback"
                                                            v-if="
                                                                nameEnErrors.length >
                                                                    0
                                                            "
                                                            >{{
                                                                nameEnErrors[0]
                                                            }}</span
                                                        >
                                                        <div
                                                            class="invalid-feedback"
                                                            v-if="
                                                                errors.has(
                                                                    'name_en'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                errors.first(
                                                                    "name_en"
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="mb-2">
                                                        <label
                                                            class="required"
                                                            >{{
                                                                $t(
                                                                    "customer.nameKh"
                                                                )
                                                            }}</label
                                                        >
                                                        <input
                                                            v-model.trim="
                                                                name_kh
                                                            "
                                                            type="text"
                                                            class="form-control"
                                                            :class="{
                                                                'is-invalid':
                                                                    errors.has(
                                                                        'name_kh'
                                                                    ) ||
                                                                    $v.name_kh
                                                                        .$error
                                                            }"
                                                        />
                                                        <span
                                                            class="invalid-feedback"
                                                            v-if="
                                                                nameEnErrors.length >
                                                                    0
                                                            "
                                                            >{{
                                                                nameEnErrors[0]
                                                            }}</span
                                                        >
                                                        <div
                                                            class="invalid-feedback"
                                                            v-if="
                                                                errors.has(
                                                                    'name_kh'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                errors.first(
                                                                    "name_kh"
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="tw-mt-2 tw-text-right"
                                                    >
                                                        <a
                                                            class="ivu-btn ivu-btn-primary ivu-btn-small"
                                                            @click.prevent="
                                                                onSaveVillage
                                                            "
                                                        >
                                                            <span>Save</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Poptip>
                                            <a
                                                class="btn btn-default"
                                                v-tooltip="$t('add')"
                                                :disabled="!model.commune_code"
                                                v-else
                                            >
                                                <i
                                                    class="fas fa-circle-notch fa-spin"
                                                    v-if="villageSaving"
                                                ></i>
                                                <i
                                                    class="fas fa-plus"
                                                    v-else
                                                ></i>
                                            </a>
                                        </div>
                                    </ts-loading-banner>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('village_code')"
                                    >
                                        {{ errors.first("village_code") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-3"
                                >
                                    {{ $t("customer.street") }}
                                </label>
                                <div class="col-md-8">
                                    <input
                                        type="text"
                                        v-model="model.street_no"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'street_no'
                                            )
                                        }"
                                    />
                                    <div
                                        v-if="errors.has('street_no')"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.first("street_no") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-3"
                                >
                                    {{ $t("customer.house") }}
                                </label>
                                <div class="col-md-8">
                                    <input
                                        type="text"
                                        v-model="model.house_no"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has('house_no')
                                        }"
                                    />
                                    <div
                                        v-if="errors.has('house_no')"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.first("house_no") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-3"
                                >
                                    {{ $t("customer.description") }}
                                </label>
                                <div class="col-md-8">
                                    <textarea
                                        type="text"
                                        v-model="model.description"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'description'
                                            )
                                        }"
                                        rows="3"
                                    ></textarea>
                                    <div
                                        v-if="errors.has('description')"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.first("description") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
        <Spin size="large" fix v-if="loadingPreData"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState, mapActions } from "vuex";
import { isEmpty } from "lodash";
import { required } from "vuelidate/lib/validators";

export default {
    name: "address-form",
    props: ["value", "validate", "clear"],
    data() {
        return {
            model: {},
            loadingPreData: false,
            errors: new Errors(),
            province_change: false,
            district_change: false,
            commune_change: false,
            districts: [],
            communes: [],
            villages: [],
            villageSaving: false,
            name_en: null,
            name_kh: null
        };
    },
    created() {
        this.model = this.value;
    },
    validations: {
        name_en: { required },
        name_kh: { required },
        villageForm: ["name_en", "name_kh"]
    },
    computed: {
        ...mapState("creditOperation/customer", ["provinces", "edit_data"]),
        nameEnErrors() {
            const errors = [];
            if (!this.$v.name_en.$error) return errors;
            !this.$v.name_en.required && errors.push("Required");
            return errors;
        },
        nameKhErrors() {
            const errors = [];
            if (!this.$v.name_kh.$error) return errors;
            !this.$v.name_kh.required && errors.push("Required");
            return errors;
        }
    },
    methods: {
        ...mapActions("creditOperation/customer", [
            "getProvince",
            "getDistrict",
            "getCommune",
            "getVillage"
        ]),
        async getProvince() {
            this.loadingPreData = true;
            await this.$store
                .dispatch("creditOperation/customer/getProvince")
                .then(() => {
                    this.model = this.value;
                    this.setEditData();
                    this.loadingPreData = false;
                })
                .catch(error => {
                    this.loadingPreData = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        provinceChange(province_code) {
            this.province_change = true;
            this.getDistrict(province_code)
                .then(response => {
                    this.districts = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.province_change = false;
                });
        },

        districtChange(district_code) {
            this.district_change = true;
            this.getCommune(district_code)
                .then(response => {
                    this.communes = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.district_change = false;
                });
        },

        communeChange(commune_code) {
            this.commune_change = true;
            this.getVillage(commune_code)
                .then(response => {
                    this.villages = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.commune_change = false;
                });
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                if (this.edit_data.province_code) {
                    this.provinceChange(this.edit_data.province_code);
                }
                if (this.edit_data.district_code) {
                    this.districtChange(this.edit_data.district_code);
                }
                if (this.edit_data.commune_code) {
                    this.communeChange(this.edit_data.commune_code);
                }
                this.model.province_code = this.edit_data.province_code;
                this.model.district_code = this.edit_data.district_code;
                this.model.commune_code = this.edit_data.commune_code;
                this.model.village_code = this.edit_data.village_code;
                this.model.street_no = this.edit_data.street_no;
                this.model.house_no = this.edit_data.house_no;
                this.model.description = this.edit_data.description;
            }
        },
        async onSaveVillage() {
            this.$v.villageForm.$touch();
            if (this.$v.villageForm.$invalid) return;

            this.villageSaving = true;
            this.$store
                .dispatch("creditOperation/customer/addVillage", {
                    name_en: this.name_en,
                    name_kh: this.name_kh,
                    commune_code: this.model.commune_code
                })
                .then(response => {
                    this.villages.push({
                        name_en: response.resource.name_en,
                        name_kh: response.resource.name_kh,
                        village_code: response.resource.village_code
                    });
                    this.model.village_code = response.resource.village_code;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.villageSaving = false;
                    this.name_en = "";
                    this.name_kh = "";
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CUSTOMER PROFILE",
                desc: not.text
            });
        }
    },
    watch: {
        validate: {
            handler() {
                this.errors = new Errors(this.validate.errors);
            },
            deep: true
        },
        "model.province_code": function(value) {
            if (value) {
                this.getDistrict(value);
            }
        },
        "model.district_code": function(value) {
            if (value) {
                this.getCommune(value);
            }
        },
        "model.commune_code": function(value) {
            if (value) {
                this.getVillage(value);
            }
        },
        clear: function(value) {
            if (value == true) {
                this.model = this.value;
            }
        }
    }
};
</script>
