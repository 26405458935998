<template>
    <div>
        <div class="row">
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4">{{
                                $t('customer.nameKh')
                            }}</label>
                            <div class="col-md-8">
                                <input
                                    v-model.trim="model.customer_name_kh"
                                    ref="customer_name_kh"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'customer_name_kh'
                                        )
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('customer_name_kh')"
                                >
                                    {{ errors.first('customer_name_kh') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label
                                class="form-label col-form-label col-md-4 required"
                                >{{ $t('customer.nameEn') }}</label
                            >
                            <div class="col-md-8">
                                <input
                                    v-model.trim="model.customer_name_en"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            errors.has('customer_name_en') ||
                                            $v.model.customer_name_en.$error
                                    }"
                                />
                                <span
                                    class="invalid-feedback"
                                    v-if="customerNameEnErrors.length > 0"
                                    >{{ customerNameEnErrors[0] }}</span
                                >
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('customer_name_en')"
                                >
                                    {{ errors.first('customer_name_en') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label
                                class="form-label col-form-label col-md-4 required"
                            >
                                {{ $t('customer.sex') }}
                            </label>
                            <div class="col-md-8">
                                <div class="form-control-plaintext">
                                    <div class="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            class="form-check-input"
                                            id="male"
                                            v-model="model.gender"
                                            value="Male"
                                            @input="$v.model.gender.$touch()"
                                            :class="{
                                                'is-invalid':
                                                    errors.has('gender') ||
                                                    $v.model.gender.$error
                                            }"
                                        />
                                        <label
                                            for="male"
                                            class="form-check-label"
                                            >Male</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            class="form-check-input"
                                            id="female"
                                            v-model="model.gender"
                                            value="Female"
                                            @input="$v.model.gender.$touch()"
                                            :class="{
                                                'is-invalid':
                                                    errors.has('gender') ||
                                                    $v.model.gender.$error
                                            }"
                                        />
                                        <label
                                            for="female"
                                            class="form-check-label"
                                            >Female</label
                                        >
                                    </div>
                                    <br />
                                    <span
                                        class="text-danger"
                                        v-if="genderErrors.length > 0"
                                        >{{ genderErrors[0] }}</span
                                    >
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('gender')"
                                    >
                                        {{ errors.first('gender') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4 required">{{
                                $t('customer.dateOfBirth')
                            }}</label>
                            <div class="col-md-8">
                                <DatePicker
                                    type="date"
                                    :value="model.date_of_birth"
                                    style="width:100%"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="onChangeDateOfBirth"
                                    :class="{
                                        'is-invalid':
                                            errors.has(
                                                'date_of_birth'
                                            ) ||
                                            $v.model.date_of_birth
                                                .$error
                                    }"
                                ></DatePicker>
                                <span
                                    class="text-danger"
                                    v-if="dateOfBirthErrors.length > 0"
                                    >{{ dateOfBirthErrors[0] }}</span
                                >
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('date_of_birth')"
                                >
                                    {{ errors.first('date_of_birth') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4">
                                {{ $t('customer.marritalStatus') }}
                            </label>
                            <div class="col-md-8">
                                <select
                                    v-model.number="model.marrital_status"
                                    class="form-select"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'marrital_status'
                                        )
                                    }"
                                >
                                    <option
                                        :value="null"
                                        disabled
                                        selected
                                        style="display: none;"
                                        >{{ $t('select') }}</option
                                    >
                                    <option
                                        v-for="(value, index) in marritalStatus"
                                        :key="index"
                                        :value="value"
                                    >
                                        {{ value }}
                                    </option>
                                </select>
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('marrital_status')"
                                >
                                    {{ errors.first('marrital_status') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4">
                                {{ $t('customer.occupation') }}
                            </label>
                            <div class="col-md-8">
                                <input
                                    v-model.trim="model.occupation"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has('occupation')
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('occupation')"
                                >
                                    {{ errors.first('occupation') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4 required">{{
                                $t('customer.contactNo')
                            }}</label>
                            <div class="col-md-8">
                                <input
                                    v-model.trim="model.contact_no"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            errors.has('contact_no') ||
                                            $v.model.contact_no.$error
                                    }"
                                />
                                <span
                                    class="text-danger"
                                    v-if="contactNoErrors.length > 0"
                                    >{{ contactNoErrors[0] }}</span
                                >
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('contact_no')"
                                >
                                    {{ errors.first('contact_no') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4">{{
                                $t('customer.email')
                            }}</label>
                            <div class="col-md-8">
                                <input
                                    v-model.trim="model.email"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has('email')
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('email')"
                                >
                                    {{ errors.first('email') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <fieldset>
                            <legend class="text-blue">
                                {{ $t('customer.ssn') }}
                            </legend>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{ $t('customer.documentType') }}</label
                                >
                                <div class="col-md-7">
                                    <select
                                        v-model.number="model.doc_type_id"
                                        class="form-select"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'doc_type_id'
                                            )
                                        }"
                                    >
                                        <option
                                            :value="null"
                                            selected
                                            >{{ $t('select') }}</option
                                        >
                                        <option
                                            v-for="(doc_type,
                                            index) in documentTypes"
                                            :key="index"
                                            :value="doc_type.doc_type_id"
                                        >
                                            {{ doc_type.doc_type_en }} | {{ doc_type.doc_type_kh }}
                                        </option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('doc_type_id')"
                                    >
                                        {{ errors.first('doc_type_id') }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{ $t('customer.nidOrpassport') }}</label
                                >
                                <div class="col-md-7">
                                    <input
                                        v-model.trim="model.ssn_no"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has('ssn_no')
                                        }"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('ssn_no')"
                                    >
                                        {{ errors.first('ssn_no') }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{
                                        $t('customer.nidOrpassportIssuedDate')
                                    }}</label
                                >
                                <div class="col-md-7">
                                    <DatePicker
                                        type="date"
                                        :value="model.issued_date"
                                        style="width:100%"
                                        placeholder="DD-MM-YYYY"
                                        format="dd-MM-yyyy"
                                        @on-change="onChangeIssuedDate"
                                    ></DatePicker>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('issued_date')"
                                    >
                                        {{ errors.first('issued_date') }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{
                                        $t('customer.nidOrpassportExpiryDate')
                                    }}</label
                                >
                                <div class="col-md-7">
                                    <DatePicker
                                        type="date"
                                        :value="model.expiry_date"
                                        style="width:100%"
                                        placeholder="DD-MM-YYYY"
                                        format="dd-MM-yyyy"
                                        @on-change="onChangeExpiryDate"
                                    ></DatePicker>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('expiry_date')"
                                    >
                                        {{ errors.first('expiry_date') }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{
                                        $t('customer.nidOrpassportIssuedAt')
                                    }}</label
                                >
                                <div class="col-md-7">
                                    <textarea
                                        v-model.trim="model.issue_at"
                                        rows="7"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has('issue_at')
                                        }"
                                    ></textarea>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('issue_at')"
                                    >
                                        {{ errors.first('issue_at') }}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="d-flex flex-column align-items-center">
                    <ts-image-input
                        class="image-input"
                        :file="file"
                        @input="file => (model.photo = file)"
                        :width="175"
                        :height="225"
                        crop
                    ></ts-image-input>
                    <ts-button @click="() => (showForm = true)" class="tw-mt-2">
                        <i class="fas fa-camera mr-2"></i>
                        {{ $t('customer.takePhoto') }}
                    </ts-button>
                    <div style="color:#cc4946;margin:4px 0 0">
                        {{ errors.first('photo') }}
                    </div>
                </div>
            </div>
        </div>
        <!-- webcam -->
        <div v-if="showForm">
            <Modal
                :title="$t('customer.takePhoto')"
                v-model="showForm"
                draggable
                sticky
                scrollable
                :footer-hide="true"
                width="690"
            >
                <web-cam @close="getPhoto" />
            </Modal>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'
import WebCam from './web-cam'
import { required } from 'vuelidate/lib/validators'
import { isEmpty } from 'lodash'

export default {
    name: 'profileInformation',
    props: ['value', 'validate', 'clear'],
    components: {
        WebCam
    },
    data () {
        return {
            model: {},
            loadingPreData: false,
            showForm: false,
            errors: new Errors(),
            file: require('../../../../assets/staff-default-man.png'),
            marritalStatus: ['Single', 'Married']
        }
    },
    validations: {
        model: {
            customer_name_en: { required },
            date_of_birth: { required },
            contact_no: { required },
            gender: { required }
        },
        form: [
            'model.customer_name_en',
            "model.date_of_birth",
            "model.contact_no",
            'model.gender'
        ]
    },
    computed: {
        ...mapState('creditOperation/customer', ['photoBlob', 'edit_data', 'documentTypes']),
        dateOfBirthErrors() {
            const errors = [];
            if (!this.$v.model.date_of_birth.$error) return errors;
            !this.$v.model.date_of_birth.required && errors.push("Required");
            return errors;
        },
        contactNoErrors() {
            const errors = [];
            if (!this.$v.model.contact_no.$error) return errors;
            !this.$v.model.contact_no.required && errors.push("Required");
            return errors;
        },
        customerNameEnErrors () {
            const errors = []
            if (!this.$v.model.customer_name_en.$error) return errors
            !this.$v.model.customer_name_en.required && errors.push('Required')
            return errors
        },
        genderErrors () {
            const errors = []
            if (!this.$v.model.gender.$error) return errors
            !this.$v.model.gender.required && errors.push('Required')
            return errors
        }
    },
    methods: {
        async getDocumentType() {
            this.loadingPreData = true;
            await this.$store
                .dispatch("creditOperation/customer/getDocumentType")
                .then(() => {
                    this.model = this.value;
                    this.setEditData();
                    this.loadingPreData = false;
                })
                .catch(error => {
                    this.loadingPreData = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        validateForm () {
            this.$v.form.$touch()
            var isValid = !this.$v.form.$invalid
            return isValid
        },
        async getReligion () {
            this.religionOptions = null
            let response = await this.fetchReligion()
            this.religionOptions = response.data.map(el => ({
                id: el.id,
                label: el.religion
            }))
        },
        getPhoto () {
            this.file = this.readBlob(this.photoBlob)
            this.model.photo = new File([this.photoBlob], 'photo', {
                type: 'image/jpeg'
            })
            this.showForm = false
            this.$store.commit('creditOperation/customer/SET_PHOTO_BLOB', '')
        },
        readBlob (blob) {
            return window.URL.createObjectURL(
                new Blob([blob], { type: 'image/jpeg' })
            )
        },
        onChangeDateOfBirth (date) {
            this.model.date_of_birth = date
        },
        onChangeIssuedDate (date) {
            this.model.issued_date = date
        },
        onChangeExpiryDate (date) {
            this.model.expiry_date = date
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.photo = this.edit_data.photo
                this.file = this.model.photo
            }
        }
    },
    watch: {
        validate: {
            handler () {
                this.errors = new Errors(this.validate.errors)
            },
            deep: true
        },
        clear: function (value) {
            if (value == true) {
                this.model = this.value
                this.file = ''
            }
        }
    }
}
</script>
